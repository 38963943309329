import { useRef } from "react";

export type Result = {
  trigger: (callback: (file: File) => void, capture?: string) => void;
};

const createFileInput = (capture?: string) => {
  const $fileInput = document.createElement("input");
  if (capture) {
    $fileInput.capture = capture;
  }
  $fileInput.type = "file";
  return $fileInput;
};

const useFile = (): Result => {
  const fileInputRef = useRef(createFileInput());
  const fileCaptureInputRef = useRef(createFileInput("camera"));

  const trigger = (callback: (file: File) => void, capture?: string) => {
    if (capture) {
      fileCaptureInputRef.current.onchange = (e) => {
        // @ts-ignore
        callback(e.target.files[0]);
      };
      fileCaptureInputRef.current.click();
    } else {
      fileInputRef.current.onchange = (e) => {
        // @ts-ignore
        callback(e.target.files[0]);
      };
      fileInputRef.current.click();
    }
  };

  return {
    trigger,
  };
};

export default useFile;
