import NavBar from "@/components/NavBar";
import useFile from "@/hooks/useFile";
import globalModel from "@/models/global.model";
import systemModel from "@/models/system.model";
import { privacyString } from "@/utils/string";
import { useReactive } from "ahooks";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Cell,
  Dialog,
  Field,
  Form,
  Popup,
  Image as RvImage,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    nicknamePopupVisible: false,
  });
  const { user } = useSnapshot(globalModel.state);
  const { trigger } = useFile();
  const [form] = Form.useForm();

  const handleUpload = async (file: File) => {
    if (!!file) {
      const update = Toast({
        type: "loading",
        message: "正在上传...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e!.target!.result as string;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = 400;
            canvas.height = 300;
            context!.drawImage(img, 0, 0, 400, 300);
            canvas.toBlob(async (file) => {
              const formData = new FormData();
              formData.append("file", file as File);
              const res = await systemModel.upload(formData);
              if (res?.code === 200) {
                update.config({
                  type: "success",
                  message: "上传成功",
                  forbidClick: true,
                });
                await globalModel.updateOwner({
                  avatar: res?.data?.url,
                });
              }
            });
          };
        };
      } finally {
        setTimeout(() => {
          update.clear();
        }, 2000);
      }
    }
  };

  return (
    <div className={styles.page}>
      <NavBar title="设置" />
      <Cell
        center
        title="头像"
        value={
          <RvImage
            className={styles.avatar}
            src={
              user?.avatar
                ? user?.avatar
                : require("@/assets/images/logo-dark.png").default
            }
            round
          />
        }
        onClick={() => trigger(handleUpload)}
      />
      <Cell
        center
        title="昵称"
        value={user?.nickname}
        onClick={() => {
          form.setFieldValue("nickname", user.nickname);
          state.nicknamePopupVisible = true;
        }}
      />
      <Popup
        className={styles.popup}
        visible={state.nicknamePopupVisible}
        closeable
        title="修改昵称"
        position="bottom"
        round
        onClose={() => (state.nicknamePopupVisible = false)}
      >
        <Form
          className={styles.form}
          form={form}
          footer={
            <div className={styles.footer}>
              <Button
                className={styles.submit}
                type="primary"
                block
                onClick={async () => {
                  try {
                    await form.validateFields();
                    form.submit();
                  } catch (e: any) {
                    if (e && e.errorFields) {
                      Toast({
                        message: e.errorFields[0]?.errors[0],
                        position: "bottom",
                      });
                    }
                  }
                }}
              >
                保存
              </Button>
            </div>
          }
          onFinish={async (values: any) => {
            const update = Toast({
              type: "loading",
              message: "正在保存...",
              forbidClick: true,
              duration: 0,
            });
            const res = await globalModel.updateOwner({
              ...values,
            });
            if (res?.code === 200) {
              update.config({
                type: "success",
                message: "保存成功",
                forbidClick: true,
              });
              state.nicknamePopupVisible = false;
            }
            setTimeout(() => {
              update.clear();
            }, 2000);
          }}
        >
          <Form.Item
            name="nickname"
            noStyle
            rules={[
              { required: true, message: "昵称不能为空" },
              {
                min: 2,
                message: "昵称不能少于2个字符",
              },
              {
                max: 10,
                message: "昵称不能多于10个字符",
              },
            ]}
          >
            <Field clearable />
          </Form.Item>
        </Form>
      </Popup>
      <Cell center title="手机号" value={privacyString(user?.mobile, 4, 4)} />
      <Cell
        center
        title="实名认证"
        value={user?.isReal ? "已实名" : "待实名"}
        onClick={() => {
          if (user?.isReal) {
            Toast("账号已实名");
          } else {
            nav("/setting/auth");
          }
        }}
      />
      <Cell
        center
        isLink
        title="安全密码"
        onClick={() => {
          nav("/setting/security");
        }}
      />
      <div className={styles.footer}>
        <Button
          className={styles.submit}
          type="primary"
          block
          onClick={async () => {
            if (await Dialog.confirm({ title: "确定要退出登录吗?" })) {
              await globalModel.logout();
            }
          }}
        >
          退出登录
        </Button>
      </div>
    </div>
  );
};
